import React from "react"
import { Menu, MenuItem, IconButton, Box } from "@material-ui/core"
import MoreVertIcon from "@material-ui/icons/MoreVert"

export const HamburgerMenu = ({ options, onClick }) => {
  const [open, setOpen] = React.useState(false)
  const ref = React.useRef()
  return (
    <Box ref={ref} position="relative">
      <IconButton onClick={() => setOpen(true)}>
        <MoreVertIcon />
      </IconButton>
      <Menu open={open} anchorEl={ref.current} onClose={() => setOpen(false)}>
        {options.map((option) => (
          <MenuItem
            key={option}
            onClick={() => {
              setOpen(false)
              onClick(option)
            }}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  )
}

export default HamburgerMenu
