import React, { useState } from "react"
import RejectedLabelsTable from "./RejectedLabelsTable"
import ViewRejectedLabelContent from "./ViewRejectedLabelContent"

export const RejectedLabels = () => {
  const [selectedWork, setSelectedWork] = useState(null)

  return (
    <>
      {!selectedWork && (
        <RejectedLabelsTable
          onWorkSelected={(work) => {
            setSelectedWork(work)
          }}/>
      )}
      {selectedWork && (
        <ViewRejectedLabelContent work={selectedWork} clearSelected={()=>{
          setSelectedWork(null)
        }}/>
      )}
    </>
  )
}

export default RejectedLabels
