import React from "react"
import { Box, Grid, styled } from "@material-ui/core"
import { useDatasetCompletion } from "udt-review-hooks"

const ValueContainer = styled("div")({
  textAlign: "center",
  fontSize: 32,
  fontWeight: "bold",
})

const TitleContainer = styled("div")({
  textAlign: "center",
  fontSize: 18,
})

export const DatasetCompletion = () => {
  const { datasetCompletion } = useDatasetCompletion()
  const {
    totalSamplesCount,
    completedSamplesCount,
    reviewedLabelsCount,
    submittedLabelsCount,
  } = datasetCompletion || {}
  return (
    <Box>
      <Grid container row>
        <Grid item xs={3} style={{ borderRight: "1px solid" }}>
          <ValueContainer>{submittedLabelsCount || "0"}</ValueContainer>
          <TitleContainer>Submitted Labels</TitleContainer>
        </Grid>
        <Grid item xs={3} style={{ borderRight: "1px solid" }}>
          <ValueContainer>{reviewedLabelsCount || "0"}</ValueContainer>
          <TitleContainer>Reviewed Labels</TitleContainer>
        </Grid>
        <Grid item xs={3} style={{ borderRight: "1px solid" }}>
          <ValueContainer>{completedSamplesCount || "0"}</ValueContainer>
          <TitleContainer>Completed Samples</TitleContainer>
        </Grid>
        <Grid item xs={3}>
          <ValueContainer>{totalSamplesCount || "0"}</ValueContainer>
          <TitleContainer>Total Samples</TitleContainer>
        </Grid>
      </Grid>
    </Box>
  )
}

export default DatasetCompletion
