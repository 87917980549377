import React, { useState } from "react"
import {
  colors,
  Menu,
  Box,
  Button,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TextField,
  MenuItem,
  Switch,
} from "@material-ui/core"
import EditIcon from "@material-ui/icons/Edit"
import moment from "moment"
import AddUserDialog from "./AddUserDialog"
import { useTeam, useAddUser } from "udt-review-hooks"
import Select from "@material-ui/core/Select"
import { EditUserDialog } from "./EditUserDialog"
import HamburgerMenu from "./HamburgerMenu"

export const TeamTable = () => {
  const [openAddUserDialog, setOpenAddUserDialog] = useState(false)
  const [openEditUserDialog, setOpenEditUserDialog] = useState(false)
  const [userToEdit, setUserToEdit] = useState(null)
  const {
    team,
    addUserDataset,
    removeUserDataset,
    editUser,
    reloadTeam,
    deleteUser,
  } = useTeam()
  const addUser = useAddUser()

  const onToggleUserDataset = (event, userId) => {
    if (event.target.checked) {
      addUserDataset(userId)
    } else {
      removeUserDataset(userId)
    }
  }

  const onUserAction = async (operation, user) => {
    if (operation === "edit") {
      setUserToEdit(user)
      setOpenEditUserDialog(true)
    } else if (operation === "delete") {
      await deleteUser(user.user_id)
      reloadTeam()
    }
  }

  const onEditSubmit = async (userId, props) => {
    await editUser(userId, props)
    setOpenEditUserDialog(false)
  }

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Role</TableCell>
            <TableCell>Last Activity</TableCell>
            <TableCell>Assigned</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {team &&
            team.map((p, i) => (
              <TableRow key={i}>
                <TableCell>{p.name}</TableCell>
                <TableCell>{p.email}</TableCell>
                <TableCell>
                  <Box display="flex" alignItems="center">
                    <Box width={74}>{p.role}</Box>
                  </Box>
                </TableCell>
                <TableCell>
                  {!p.last_activity ? "" : moment(p.last_activity).fromNow()}
                </TableCell>
                <TableCell>
                  <Switch
                    checked={Boolean(p.user_dataset_id)}
                    onChange={(e) => onToggleUserDataset(e, p.user_id)}
                    name="checkedA"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                </TableCell>
                <TableCell>
                  <HamburgerMenu
                    options={["Edit", "Delete"]}
                    onClick={(option) => onUserAction(option.toLowerCase(), p)}
                  />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <Box padding={2} textAlign="right">
        <Button
          color="primary"
          variant="outlined"
          onClick={() => setOpenAddUserDialog(true)}
        >
          Add Team Member
        </Button>
      </Box>
      <AddUserDialog
        open={openAddUserDialog}
        onSubmit={async (userData) => {
          await addUser(userData)
          reloadTeam()
          setOpenAddUserDialog(false)
        }}
        onClose={() => setOpenAddUserDialog(false)}
      />
      <EditUserDialog
        open={openEditUserDialog}
        user={userToEdit}
        onSubmit={onEditSubmit}
        onClose={() => setOpenEditUserDialog(false)}
      />
    </>
  )
}

export default TeamTable
