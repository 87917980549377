import React from "react"
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Box,
} from "@material-ui/core"
import { useTeamPerformance } from "udt-review-hooks"
import DatasetCompletion from "./DatasetCompletion"

export const TeamPerformanceTable = () => {
  const { teamPerformance } = useTeamPerformance()
  return (
    <>
      <Box pt={2} pb={2}>
        <DatasetCompletion />
      </Box>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell align="center">Samples Labeled</TableCell>
            <TableCell align="center">Samples Reviewed</TableCell>
            <TableCell align="center">Accuracy</TableCell>
            <TableCell align="center">Avg Time/Label</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {teamPerformance.map((p, i) => {
            const accuracy = p.acceptedAndRejectedWork
              ? (p.acceptedWork / p.acceptedAndRejectedWork) * 100
              : 0
            return (
              <TableRow key={i}>
                <TableCell>{p.name}</TableCell>
                <TableCell>{p.email}</TableCell>
                <TableCell align="center">
                  {p.labeledSamplesCount || 0}
                </TableCell>
                <TableCell align="center">
                  {p.reviewedSamplesCount || 0}
                </TableCell>
                <TableCell align="center">{accuracy.toFixed(1)}%</TableCell>
                <TableCell align="center">
                  {p.timePerLabel.toFixed(1)}s
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </>
  )
}

export default TeamPerformanceTable
