import React from "react"
import {
  CircularProgress,
  Box,
  styled,
  Button,
  colors
} from "@material-ui/core"
import AuditTrail from "./AuditTrail.js"
import UniversalSampleEditor from "../UniversalSampleEditor"
import { useSample, useReviewWork, useGetSampleSearch } from "udt-review-hooks"
import Typography from "@material-ui/core/Typography"

export const ViewRejectedLabelContent = ({work, clearSelected}) => {
  return (
    <Box display="flex">
      <Box flexGrow={1}>
        <Box
          padding={1}
          paddingBottom={2}
        >
          <Typography variant="h6">Reject reason:</Typography>
          <Typography variant="body1">{ work.message }</Typography>
        </Box>
        {work &&
          <UniversalSampleEditor
            key={work?.sample_id}
            sampleIndex={work.sample_index}
            sample={{
              ...work.sample_data,
              annotation: work.annotation
            }}
            interface={work.interface}
            onModifySample={async (sample) => {
            }}
            onExit={() => {
              clearSelected()
            }}
          />
        }
      </Box>
    </Box>
  )
}

export default ViewRejectedLabelContent
