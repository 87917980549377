import React from "react"
import {
  CircularProgress,
  Box,
  styled,
  Button,
  colors,
} from "@material-ui/core"
import AuditTrail from "./AuditTrail.js"
import UniversalSampleEditor from "../UniversalSampleEditor"
import { useSample, useReviewWork, useGetSampleSearch } from "udt-review-hooks"

export const ReviewSampleContent = ({
  sampleId,
  searchOptions,
  onChangeSample,
}) => {
  const [mode, setMode] = React.useState("view-best") // correct, view-audit-item
  const [selectedAuditItem, setSelectedAuditItem] = React.useState()
  const [
    reviewSubmissionInProgress,
    setReviewSubmissionInProgress,
  ] = React.useState(false)
  const {
    sample,
    auditTrail,
    loading,
    reloadSample,
    submitAnnotation,
  } = useSample({
    sampleId,
    withAuditTrail: true,
  })
  console.log({ sample, sampleId })
  const currentWorkId =
    mode === "view-best" ? sample?.best_work_id : selectedAuditItem?.work_id
  const { reviewWork } = useReviewWork(currentWorkId)
  const getSampleSearch = useGetSampleSearch()

  const sampleBeingViewed = React.useMemo(
    () => ({
      ...sample?.sample_data,
      annotation:
        mode === "correct"
          ? null
          : mode === "view-best"
          ? sample?.best_annotation
          : mode === "view-audit-item"
          ? selectedAuditItem?.annotation
          : null,
    }),
    [sample, mode, selectedAuditItem]
  )

  return (
    <Box display="flex">
      <Box flexGrow={1}>
        <Box
          display="flex"
          padding={1}
          paddingBottom={2}
          style={{ opacity: reviewSubmissionInProgress ? 0.5 : 1 }}
        >
          {/* <Button variant="outlined">Back</Button> */}
          <Box flexGrow={1} />
          <Button
            disabled={!currentWorkId}
            variant="outlined"
            style={
              currentWorkId
                ? { color: colors.red[600], borderColor: colors.red[300] }
                : {}
            }
            onClick={async () => {
              setReviewSubmissionInProgress(true)
              await reviewWork({
                reject: true,
                message: window.prompt("Rejection Message?"),
              })
              setReviewSubmissionInProgress(false)
              await reloadSample()
            }}
          >
            Reject
          </Button>
          <Button
            disabled={!currentWorkId}
            variant="outlined"
            style={{
              marginLeft: 8,
              ...(currentWorkId && {
                color: colors.green[800],
                borderColor: colors.green[600],
              }),
            }}
            onClick={async () => {
              setReviewSubmissionInProgress(true)
              await reviewWork({ accept: true })
              await reloadSample()
              setReviewSubmissionInProgress(false)
            }}
          >
            Approve
          </Button>
          <Button
            disabled={!currentWorkId}
            variant="outlined"
            style={{
              marginLeft: 8,
              ...(currentWorkId && {
                color: colors.green[800],
                borderColor: colors.green[600],
              }),
            }}
            onClick={async () => {
              setReviewSubmissionInProgress(true)
              await reviewWork({ accept: true })
              const { samples } = await getSampleSearch({
                ...searchOptions,
                offset: 0,
                limit: 1,
                order_by: "random",
              })
              if (samples.length > 0) {
                onChangeSample(samples[0])
              } else {
                await reloadSample()
              }
              await setReviewSubmissionInProgress(false)
            }}
          >
            Approve &amp; Next
          </Button>
          <Button
            onClick={async () => {
              const { samples } = await getSampleSearch({
                ...searchOptions,
                offset: 0,
                limit: 1,
                order_by: "random",
              })
              if (samples.length > 0) {
                onChangeSample(samples[0])
              }
            }}
            style={{ marginLeft: 8 }}
            variant="outlined"
          >
            Next
          </Button>
        </Box>
        {loading || !sample ? (
          <Box textAlign="center" pt="32px">
            <CircularProgress />
          </Box>
        ) : mode === "view-audit-item" && selectedAuditItem.type !== "work" ? (
          <pre>{JSON.stringify(selectedAuditItem, null, "  ")}</pre>
        ) : (
          <UniversalSampleEditor
            key={sample?.sample_id}
            sampleIndex={sample.sample_index}
            sample={sampleBeingViewed}
            interface={sample.interface}
            onModifySample={async (sample) => {
              setReviewSubmissionInProgress(true)
              submitAnnotation(sample.annotation)
              await reloadSample()
              setReviewSubmissionInProgress(false)
            }}
            onExit={() => {}}
          />
        )}
      </Box>
      <Box width={320} flexShrink={0} paddingLeft={1}>
        <Box color={colors.grey[700]} padding={1}>
          History
        </Box>
        <AuditTrail
          selectedItem={selectedAuditItem}
          items={auditTrail}
          bestWorkId={sample?.best_work_id}
          onSelectItem={(item) => {
            setSelectedAuditItem(item)
            setMode("view-audit-item")
          }}
        />
        <Box padding={1} textAlign="right" display="flex">
          <Button
            disabled={mode === "view-best"}
            variant="outlined"
            onClick={() => setMode("view-best")}
          >
            {mode === "view-best" ? "Viewing Best Labels" : "View Best Labels"}
          </Button>
          <Button
            onClick={() => setMode("correct")}
            color="primary"
            variant="outlined"
            style={{ marginLeft: 8 }}
          >
            Submit Correction
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default ReviewSampleContent
