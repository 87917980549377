import React, { useState, useEffect } from "react"
import {
  Button,
  Grid,
  TextField,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  MenuItem,
  colors,
} from "@material-ui/core"

export const EditUserDialog = ({ open, user, onSubmit, onClose }) => {
  const [userData, setUserData] = useState({
    user_id: "",
    name: "",
    email: "",
    password: "",
    role: "labeler",
  })

  useEffect(() => {
    if (user) {
      setUserData({
        user_id: user.user_id,
        name: user.name,
        email: user.email,
        password: "",
        role: user.role,
      })
    }
  }, [user])

  const onInputChange = (event) => {
    setUserData({
      ...userData,
      [event.target.name]: event.target.value,
    })
  }

  return (
    <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">Edit user</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              required
              label="Name"
              name="name"
              value={userData.name}
              onChange={onInputChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              label="Email"
              name="email"
              value={userData.email}
              onChange={onInputChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              label="Password"
              name="password"
              type="password"
              value={userData.password}
              onChange={onInputChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              select
              label="Role"
              name="role"
              value={userData.role}
              onChange={onInputChange}
              fullWidth
            >
              {["admin", "reviewer", "labeler"].map((role) => (
                <MenuItem key={role} value={role}>
                  {role}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => onSubmit(userData.user_id, userData)}
          color="primary"
          variant="outlined"
        >
          Submit
        </Button>
        <Button
          onClick={onClose}
          style={{
            borderColor: colors.red[200],
            color: colors.red[300],
          }}
          variant="outlined"
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default EditUserDialog
