import React from "react"
import {
  Box,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Button,
  CircularProgress,
} from "@material-ui/core"
import CheckIcon from "@material-ui/icons/Check"
import moment from "moment"
import { useRejectedLabels } from "udt-review-hooks"
import TablePagination from "@material-ui/core/TablePagination"

export const RejectedLabelsTable = ({ onWorkSelected }) => {
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const options = React.useMemo(
    () => ({
      limit: rowsPerPage,
      page: page
    }),
    [page, rowsPerPage]
  )
  const { rejectedLabels, rejectedLabelsCount, loading } = useRejectedLabels(options)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return (
    <>
      {loading ? (
        <Box p={8} textAlign="center">
          <CircularProgress size={50} />
        </Box>
      ) : (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>SI</TableCell>
              <TableCell align="center">Reviewed By</TableCell>
              <TableCell>Labeled At</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rejectedLabels &&
            rejectedLabels
                .map((s, i) => (
                  <TableRow key={i}>
                    <TableCell>{s.sample_index}</TableCell>
                    <TableCell align="center">{s.name}</TableCell>
                    <TableCell>
                      {!s.created_at
                        ? ""
                        : moment(s.created_at).fromNow()}
                    </TableCell>
                    <TableCell>
                      <Button onClick={() => onWorkSelected(s)}>
                        View
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      )}
      <div style={{ marginRight: "64px" }}>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rejectedLabelsCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
      {!rejectedLabels && (
        <Box textAlign="center" pt="32px">
          <CircularProgress size={50} color="primary" />
        </Box>
      )}
    </>
  )
}

export default RejectedLabelsTable
