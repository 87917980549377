import React, { useState } from "react"
import TeamPerformanceTable from "./TeamPerformanceTable"
import SimpleSidebar from "./SimpleSidebar"
import RejectedLabels from "./RejectedLabels"

export const Analytics = () => {
  const [selectedItem, setSelectedItem] = useState("Performance Table")
  const onSelectItem = (itemName) => {
    setSelectedItem(itemName)
  }
  return (
    <SimpleSidebar
      sidebarItems={[
        { name: "Performance Table" },
        { name: "Rejected Labels" },
      ]}
      selectedItem={selectedItem}
      onSelectItem={onSelectItem}
    >
      {selectedItem === "Performance Table" && <TeamPerformanceTable />}
      {selectedItem === "Rejected Labels" && <RejectedLabels />}
    </SimpleSidebar>
  )
}

export default Analytics
