import React from "react"
import { styled, colors } from "@material-ui/core"

export const Title = styled("div")({
  fontSize: 24,
  color: colors.grey[600],
  padding: 32,
})

export default Title
